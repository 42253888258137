import styled, { css, keyframes } from 'styled-components'
import { colours } from '@awdis/components'

type DotProps = {
  index: number
  size?: number
}

const scaleAnimation = keyframes`
 50% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
  }
`

const Dot = styled.div<DotProps>`
  ${({ size = 15 }) => css`
    height: ${size}px;
    width: ${size}px;
  `}

  animation: ${scaleAnimation} 1000ms ease infinite 0ms;
  box-sizing: border-box;
  border: 8px solid ${colours.BLACK};
  float: left;
  margin: 0 8px;
  transform: scale(0);

  ${({ index }) => css`
    &:nth-child(${index}) {
      animation-delay: ${index * 300}ms;
    }
  `}
`

export default Dot
