import * as React from 'react'
import Dot from './components/Dot'
import styled from 'styled-components'

type DotLoaderProps = {
  as?: React.ReactType<any>
  className?: string
  numberDots?: number
  height?: number
}

const defaultProps = {
  as: 'div',
  numberDots: 3,
  height: 15,
}

const DotLoaderC = function DotLoaderC({
  as: As,
  numberDots,
  height,
  ...props
}: DotLoaderProps) {
  return (
    <As {...props}>
      {Array(3)
        .fill(0)
        .map((_, index) => (
          <Dot key={index} index={index + 1} size={height} />
        ))}
    </As>
  )
}

DotLoaderC.defaultProps = defaultProps

const DotLoader = styled(DotLoaderC)`
  box-sizing: border-box;
  height: ${({ height = 15 }) => height + 5}px;
  margin-bottom: ${({ height = 15 }) => height * 1.5}px;
`

export default DotLoader
