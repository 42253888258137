import * as React from 'react'
import styled from 'styled-components'
import BasicPageContainer from '../../components/BasicPageContainer'
import Layout, { LayoutData } from '../../components/Layout'
import DatalessLayout from '../../components/Layout/DataLessLayout'
import { Title } from '@awdis/components'
import DotLoader from '../../components/DotLoader'

type RedirectingProps = {
  pageContext?: {
    url: string
    resourceName?: string
    layoutData?: LayoutData
  }
}

const Message = styled.p`
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
`

class Redirecting extends React.Component<RedirectingProps> {
  static defaultProps = {}

  interval: NodeJS.Timeout

  componentDidMount() {
    this.interval = setInterval(() => {
      const { pageContext: { url = '' } = {} } = this.props
      if (window && url) window.location.replace(url)
    }, 2000)
  }

  componentWillUnmount() {
    clearTimeout(this.interval)
  }

  render() {
    const {
      pageContext: { layoutData, resourceName },
    } = this.props
    const content = (
      <BasicPageContainer>
        <DotLoader />
        <Title size="big" textCase="normal">
          Redirecting{resourceName ? ` to ${resourceName}` : ''}…
        </Title>
        <Message>You are being redirected to an external resource.</Message>
      </BasicPageContainer>
    )

    return layoutData ? (
      <Layout data={layoutData}>{content}</Layout>
    ) : (
      <DatalessLayout>{content}</DatalessLayout>
    )
  }
}

export default Redirecting
